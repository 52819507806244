<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title> <v-img  :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->

 <div class="container">
    <div :style="{display:'flex',justifyContent:'center'}">
        <v-btn @click="menu(1)" :color="(menu_selected===1)? fill:'#ffffff'" :dark="(menu_selected===1)? true:false" class="mx-1" rounded>New Admin&nbsp;<fai icon="user-plus"/></v-btn>
        <v-btn @click="menu(2)" :color="(menu_selected===2)? fill:'#ffffff'" :dark="(menu_selected===2)? true:false" class="mx-1" rounded>Admin List&nbsp;<fai icon="book"/></v-btn>
    </div>
    
    <div :style="{display:'flex',justifyContent:'center'}">
    <div class="form mt-2 pa-5" v-if="menu_selected===1">
            <div class="d-flex justify-center"><v-chip large outlined><fai icon="user-plus"/>&nbsp;Add New Web Admin</v-chip></div>
            <v-text-field v-model="admin_email" type="email" label="Email Address"></v-text-field>

            <v-text-field v-model="admin_username" type="username" label="Username"></v-text-field>

            <v-text-field v-model="admin_password" type="password" label="Admin Password"></v-text-field>
            <v-select v-model="admin_level" :items="admin_levels" label="Admin level" hint="Level 1 is the highest level."></v-select>
            <div :style="{display:'flex',justifyContent:'center'}">
            <v-btn fab @click="addAdmin"><fai icon="plus"/> </v-btn>
            </div>
    </div>

    <div class="regadmins mt-2" v-if="menu_selected===2">
            <div v-for="(i , index) in admins" :key="index" class="mt-3">
               <v-chip><fai :color="fill" icon="angle-double-right"/> &nbsp;{{i.email}} &nbsp;<v-btn @click.stop="deleteAdmin(index)" icon fab small><fai icon="trash-can"/></v-btn></v-chip>
            </div>
        </div>

    </div>
    </div>

      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
               
            ],
            menu_selected:1,
            progress:false,
            alert_box:false,
            alert_type:"success",
            alert_message:undefined,
            fill:'#fe7b00',
            admin_levels:[
                {text:"Level 1" , value:1},
                {text:"Level 2", value:2},
                {text:"Level 3" , value:3}
            ],
            admins:[
                
            ],
            /**
             * FORM DATA
             */
            admin_email:"",
            admin_password:"",
            admin_level:undefined,
            admin_username:''
        }
    },
    components:{
  
    },
    methods:{
    menu(num){
            this.menu_selected=num;
     },
     addAdmin(){
            this.$store.dispatch('openProgress');
            const form = new FormData();
            form.append("email" , this.admin_email);
            form.append("level" , this.admin_level);
            form.append("password" , this.admin_password);
            form.append('username' , this.admin_username)
           this.axios({
               url:this.$store.getters.get_api+"adminCreate",
               method:"post",
               data:form,
               headers:{
                   "Access-Control-Allow-Origin":'*',
                   "Authorization":"Bearer "+this.$store.getters.get_token
               }
           }).then((res)=>{
                const data = res.data;
                //console.log(data)
               if(!data.error){
                    this.$store.dispatch('closeProgress');
                    this.progress = false;
                    this.alert_box = true;
                    this.alert_type = "success"
                    this.alert_message="Admin added successfully"
                    this.admins = data.message
               }
               else{

                    this.$store.commit('set_progress',false);
                    this.$store.commit('set_error',true);
                    this.$store.commit('set_message',data.message);
               }
            }).catch((error)=>{
                  this.$store.commit('set_progress',false);
                  this.$store.commit('set_error',true);
                  this.$store.commit('set_message','Network Error');
                  throw error;
            });
        },
        deleteAdmin(index){
            this.$store.dispatch('openProgress');
            this.axios({

            }).then((response)=>{
                const data = response.data
                if(!data.error){
                    this.admins.splice(index, 1)
                    this.$store.dispatch('closeProgress')
                }
            }).catch((error)=>{
              this.$store.commit('set_progress',false);
                  this.$store.commit('set_error',true);
                  this.$store.commit('set_message','Network Error');
                return error;
            })
        },
        fetchAdmins(){
            this.$store.dispatch('openProgress');
            this.axios({
                url:this.$store.getters.get_api+'fetchAdmins',
                method:"get",
                headers:{
                    'Access-Control-Allow-Origin':'*',
                    "Authorization":"Bearer "+this.$store.getters.get_token
                }
            }).then((data)=>{
               // console.log(data.data.message)
                if(!data.data.error){
                    this.$store.dispatch('closeProgress')
                    this.admins = data.data.message;
                }
                else{
                    this.$store.commit('set_progress',false);
                  this.$store.commit('set_error',true);
                  this.$store.commit('set_message',data.message);
                }
            }).catch((error)=>{
                    this.$store.commit('set_progress',false);
                    this.$store.commit('set_error',true);
                    this.$store.commit('set_message','Network Error');
                    throw error;
            });
          },
         switchMenu(name){
            if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
            {
              this.$router.push('/'+name.toLowerCase());
            }
         },
    },
    created(){

    },
    mounted(){
       this.sideMenu=this.$store.getters.get_menu;
       this.fetchAdmins()
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       
        //END OF WATCH
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
.container{
    display:flex;
    justify-content:center;
    flex-direction:column;
}
.form{
     border-radius: 5%;
    background: #ffffff;
    box-shadow: 16px 16px 40px #bebebe,
                        -16px -16px 40px #ffffff;
    padding:10px;
}
@media screen and (max-width:600px){
   .form{
       width:100%;
   }
}
@media screen and (min-width:600px){
   .form{
       max-width:400px;
       min-width:400px
   }
}
</style>