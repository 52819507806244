<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->

<div class="container mt-4">
    <div class="regadmins">
       <v-chip class="mb-3"><fai icon="gear"/>&nbsp;Web Settings</v-chip>

       <v-select v-if="ck_data.length>1" outlined :items="ck_data" v-model="ck_selected_value" label="Select Settings for Edit"> </v-select>
         <v-card flat class="box" color="#e0e0e0">
          <span>{{ck_selected_name}}</span>
          <ckeditor :editor="editor" v-model="editorData" tag-name="textarea" :config="editorConfig"></ckeditor>
          <!--<v-alert class="mt-2" dense type="warning">Please,submit twice to effect the change.</v-alert>-->
         <v-card-actions>
         <div><v-btn dark @click.stop="updateSettingCK(ck_selected_index)" color="blue" rounded>Update Record</v-btn></div>
         </v-card-actions>
         </v-card>

       

      <div v-for="(i , index) in settings" :key="index" class="mt-3">

         <v-card v-if="i.name!=='privacy_policy' && i.name!=='return_policy'&& i.name !=='terms_and_conditions' && i.name!=='about_us'" flat class="box" color="#e0e0e0">
         <v-text-field :type="(i.name==='android_version'||i.name==='android_bundleid'||i.name==='ios_version'||i.name==='ios_bundleid')?'text':'number'" outlined dense v-model="i.value" :label="i.name"></v-text-field>
         <v-card-actions>
         <v-btn fab small @click.stop="deleteSetting(index)"><fai icon="trash-can"/></v-btn>
         <v-btn dark @click.stop="updateSetting(index)" color="blue" small rounded>Update</v-btn>
         </v-card-actions>
         </v-card>

      </div>
<!--
<div>
  <v-chip class="mb-3"><fai icon="gear"/>&nbsp;Shipping Fee Settings</v-chip>
  <v-select dense @change="loadFee" v-model="selected_lga" label="Select LGA" :items="lga" outlined></v-select>
  <v-text-field dense type="number" v-model="shipping_fee" outlined label="Shipping Fee"></v-text-field>
  <v-btn :color="theme" dark rounded @click.stop="submitShippingChngs">Update Shipping Fee</v-btn>
</div>-->

    </div>
</div>

<v-overlay v-show="overlay">
   <v-progress-circular v-show="progress" indeterminate></v-progress-circular>
   <v-alert :type="alert_type" shaped v-show="alert_box">
   {{alert_message}}
   <template v-slot:close><v-btn icon @click.stop="hideOverlay"><fai icon="close"/></v-btn></template>
   </v-alert>
</v-overlay>
      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data:()=>{
        return{
            editor:ClassicEditor,
            editorConfig:{
              toolbar:{
                items:['heading','bold','italic','link','|','blockQuote','bulletedList','numberedList','indent','|','undo','redo','selectAll',],
                height:'150px',
                allowedContent:true
              }
            },
            editorData:'',
            ck_data:[],
            ck_selected_name:'',
            ck_selected_index:-1,
            ck_selected_value:undefined,
          //
            shipping_fee:'',
            selected_lga:undefined,
            overlay:false,
            drawer: false,
            theme:'#fe7b00',
            selectedMenu:'Home',
            sideMenu:[
               
            ],
            name:undefined,
            value:undefined,
            progress:false,
            alert_box:false,
            alert_type:"success",
            alert_message:undefined,
            settings:[
                {name:"test name" , id:4 , value:"test value"},
                {name:"test name" , id:4 , value:"test value"},
                {name:"test name" , id:4, value:"test value"}
            ],
            lga:[],
            lgas_raw:[]
        }
    },
    components:{
  
    },
    methods:{
    submitShippingChngs(){
      this.overlay=true;
      this.progress=true;
      const form = new FormData();
      form.append('lga' , this.selected_lga);
      form.append('shipping_fee' , this.shipping_fee);
      this.axios({
        url:this.$store.getters.get_api+'updateShippingFee',
        method:'post',
        data:form,
        headers:{
            "Access-Control-Allow-Origin":"*",
            "Authorization":"Bearer "+this.$store.getters.get_token
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.progress=false;
          this.alert_box=true;
          this.alert_message=data.message;
          this.alert_type='success'
        }
        else{
          this.progress=false;
          this.alert_box=true;
          this.alert_message=data.message;
          this.alert_type='error'
        }
      }).catch((error)=>{
        this.progress=false;
          this.alert_box=true;
          this.alert_message='Network Error';
          this.alert_type='success'
        return error;
      })
    },
    loadFee(){
      let index;
      this.lga.forEach((e,i)=>{
        if(e.value==this.selected_lga){
          index = i
        }
      })
      this.shipping_fee = this.lgas_raw[index].shipping_fee;
    },
    fetchLGAs(){
      this.axios({
        url:this.$store.getters.get_api+'fetchStateLGAAdmin',
        method:'get',
        headers:{
            "Access-Control-Allow-Origin":"*",
            "Authorization":"Bearer "+this.$store.getters.get_token
        }
      }).then((res)=>{
        const data = res.data;
        //console.log(data)
        if(!data.error){
          this.lgas_raw = data.message;
          data.message.forEach((e)=>{
            this.lga.push({text:e.name , value:e.id});
          })
        }
      }).catch((error)=>{
        return error;
      })
    },
    createSetting(){
        this.alert_box=false
        this.progress=true
        this.overlay=true
        const form = new FormData()
        form.append("name" , this.name );
        form.append("value" , this.value);
        this.axios({
          url:this.$store.getters.get_api+'createSetting',
          method:"post",
          data:form,
          headers:{
                    "Access-Control-Allow-Origin":"*",
                    "Authorization":"Bearer "+this.$store.getters.get_token
          }
        }).then((response)=>{
          const data = response.data;
          if(!data.error){
            this.alert_box=true
            this.alert_type="success"
            this.alert_message="Settings deleted"
            this.progress = false
            this.settings.push()
          }
        }).catch((error)=>{
          return error
        });

      },
      fetchSettings(){
        this.alert_box=false
        this.progress=true
        this.overlay=true
        this.axios({
          url:this.$store.getters.get_api+'fetchSetting',
          method:"get",
          headers:{
                    "Access-Control-Allow-Origin":"*",
                    "Authorization":"Bearer "+this.$store.getters.get_token
          }
        }).then((response)=>{
          const data = response.data;
          if(!data.error){
            this.settings = data.message
            for (var i = 0; i < data.message.length; i++) {
              if( (data.message[i].name==='about_us')  || (data.message[i].name==='terms_and_conditions') || (data.message[i].name==='return_policy')  || (data.message[i].name==='privacy_policy') )
              {
                this.ck_data[i]={text:data.message[i].name,value:i};
              }
            }
            this.overlay=false
          }
        }).catch((error)=>{
          return error
        });
      },
      deleteSetting(index){
        this.alert_box=false
        this.progress=true
        this.overlay=true
        const id = this.settings[index].id;
        this.axios({
          url:this.$store.getters.get_api+'deleteSetting/'+id,
          method:"get",
          headers:{
                    "Access-Control-Allow-Origin":"*",
                    "Authorization":"Bearer "+this.$store.getters.get_token
          }
        }).then((response)=>{
          const data = response.data
          if(!data.error){
            this.alert_box=true
            this.alert_type="success"
            this.alert_message="Settings deleted"
            this.progress = false
            this.settings.splice(index,1)
          }
        }).catch((error)=>{
          return error
        })
      },
      hideOverlay(){
            this.overlay = false;
            this.progress = false;
            this.alert_box = false;
        },
      async updateSetting(index){
        this.alert_box=false
        this.progress=true
        this.overlay=true
        const setting = await this.settings[index];
        console.log(setting)
        const form = new FormData()
        form.append("id" , setting.id)
        form.append("value" , setting.value)
        this.axios({
          url:this.$store.getters.get_api+'updateSetting',
          method:"post",
          data:form,
          headers:{
                    "Access-Control-Allow-Origin":"*",
                   "Authorization":"Bearer "+this.$store.getters.get_token
          }
        }).then((response)=>{
          const data = response.data
          if(!data.error){
            this.settings[index].value = this.editorData;
            this.alert_box=true
            this.alert_type="success"
            this.alert_message="Settings updated"
            this.progress = false
          }
        }).catch((error)=>{
          return error
        })
      },
      async updateSettingCK(index){
        this.alert_box=false
        this.progress=true
        this.overlay=true
        const setting = await this.settings[index];
        console.log(setting)
        const form = new FormData()
        form.append("id" , setting.id)
        form.append("value" , this.editorData)
        this.axios({
          url:this.$store.getters.get_api+'updateSetting',
          method:"post",
          data:form,
          headers:{
                    "Access-Control-Allow-Origin":"*",
                   "Authorization":"Bearer "+this.$store.getters.get_token
          }
        }).then((response)=>{
          const data = response.data
          if(!data.error){
            this.settings[index].value = this.editorData;
            this.alert_box=true
            this.alert_type="success"
            this.alert_message="Settings updated"
            this.progress = false
          }
        }).catch((error)=>{
          return error
        })
      },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
     
     logout(){
      
      this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.commit("set_token" , undefined);
          this.$router.push("/")
        }
      }).catch((error)=>{
        return error
      })
     }
    },
    created(){
       this.fetchSettings();
    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
      //this.totalNotice();
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
      // this.fetchLGAs();
    },
    watch:{
      ck_selected_value(value){
        const sett = this.settings[value];
        this.ck_selected_name = sett.name;
        this.ck_selected_index = value;
        this.editorData = sett.value;
        },
       
    }
   
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
@.box{
  background:#e0e0e0;
}
.container{
    display:flex;
    justify-content: center;
    flex-flow:row wrap;
}
.form{
     border-radius: 5%;
    background: #ffffff;
    box-shadow: 20px 20px 40px #bebebe,
                        -20px -20px 40px #ffffff;
    padding:10px;
    max-height: 300px;
}
@media screen and (max-width:620px){
   .form{
       width:100%;
   }
   .regadmins{
    max-width:100%;
   }
}
@media screen and (min-width:640px){
   .form{
       max-width:400px;
   }
   .regadmins{
       width:50%;
   }
}

</style>