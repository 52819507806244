<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->

<div class="mt-10">
        <div class="he">
            <div class="search-field">
            <v-text-field class="search" solo dense text rounded placeholder="Order ID" hint="enter order ID">
                <template v-slot:append>
                    <fai icon="search"/>
                </template>
            </v-text-field>
            </div>

            <div>
              <v-select class="search" :items="orderStatus" solo dense text rounded  v-model="statusX"></v-select>
            </div>

            </div>
            <div>
              <v-progress-linear indeterminte v-show="progress"></v-progress-linear>
            </div>
             <v-data-table class="" :headers="headers" :items="items">
            <template v-slot:[`item.order_status`]="{ item }">
                <v-chip v-if="item.order_status===0" color="#eceff1" :style="{color:'#030303'}" dark>New Order</v-chip>
                <v-chip v-if="item.order_status===1" color="#7b1fa2" dark>Processed</v-chip>
                <v-chip v-if="item.order_status===2" color="#fe7b00" dark>Shipped</v-chip>
                <v-chip v-if="item.order_status===3" color="#fe7b00" dark>Received</v-chip>
                <v-chip v-if="item.order_status===4" color="green" dark>Completed</v-chip>
            </template><!--fe7b00-->

            <template v-slot:[`item.total_amount`]="{item}">
                <span>{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(item.total_amount)}}</span>
            </template>

            <template v-slot:[`item.action`]="{item }">
                <v-btn fab icon class="action_btn" small @click="showOrderInfo(item.order_id)"><fai icon="ellipsis-v"/></v-btn>
            </template>
        </v-data-table>

        <div class="text-center">
          <v-pagination
            v-model="current_page"
            :length="last_page"
            :total-visible="7"
          ></v-pagination>
        </div>
       <!-- <div class="mt-2 d-flex justify-center">
          <v-btn @click="loadPrevious" class="mx-1"  small rounded>Previous</v-btn>
           <v-btn @click="loadNext" class="mx-1"  small rounded>Next</v-btn>
        </div>-->
        <!---
        -->
         <v-dialog
        transition="dialog-bottom-transition"
        max-width="700"
        persistent
        v-model="order_info_a"
        >
            <v-card :style="{position:'relative'}">
                <v-progress-linear indeterminate v-show="order_progress"></v-progress-linear>
                <div class=" pb-3 pa-2" :style="{background:'#fe7b00',color:'white'}"><fai icon="book"/>&nbsp;Order Preview
                  <v-btn @click="order_info_a=!order_info_a" color="white" :style="{position:'absolute',right:'2px',top:'2px',display:'flex',alignItems:'center'}" fab icon small><fai icon="close"/></v-btn>
                </div>
                <div class="dialog_order_info pa-3">

                <div>
                <v-chip flat class="mb-1">Product Purchased</v-chip>
                <v-card color="#ffffff" class="mt-2 mx-1 d-flex cv" v-for="(item,index) in order_info_product" :key="index">
                  <v-card height="100px" width="100px" tile flat>
                    <v-img class="img mx-1" :src="$store.getters.get_api+'fetchProductPic/'+item.image"></v-img>
                  </v-card>
                  <div  class="caption" :style="{display:'flex',flexDirection:'column',flexWrap:'wrap'}">
                    <span class="subtitle" maxWidth="50px" :style="{overflowX:'ellipsis'}"><strong>{{item.product_name}}</strong></span>
                    <span class="subtitle"><strong :style="{color:'red'}">Qty: </strong>&nbsp;<strong>{{item.quantity}}</strong></span>

                    <span>Market Price: {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(item.price)}}</span>

                    <span>Purchased AT: {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(item.purchase_price)}}</span>

                    <span>Discount <strong :style="{color:'green'}">{{item.discount}}%</strong></span>

                  </div>
                </v-card>
              </div>

              <div class="cinfo pa-2" :style="{display:'flex',flexDirection:'column',flexWrap:'wrap'}">
                  <v-chip outlined class="mb-1">Order Information</v-chip>
                  <span><strong>Shipping Fee:</strong> {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(shipping_fee)}}</span>

                  <span><strong>Service Fee:</strong> {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(service_charge)}}</span>
                  <v-chip outlined class="mb-1">Contact Information</v-chip>
                  <span><strong>Firstname:</strong> {{order_info_contact.firstname}}</span>
                  <span><strong>Lastname:</strong> {{order_info_contact.lastname}}</span>
                  <span><strong>Mobile:</strong> {{order_info_contact.phonenumber}}</span>
                  <span><strong>Address:</strong> {{order_info_contact.address}}</span>
                  <span><strong>City:</strong> {{order_info_contact.city}}</span>
                  <span><strong>LGA:</strong> {{order_info_contact.LGA}}</span>
              </div>

            </div>
            <v-card-actions>
              <v-btn color="blue" dark @click="$router.push('orderInfo/'+order_id)">Process Payment</v-btn>
              <v-progress-circular class="mx-2" v-show="progressp" small indeterminate></v-progress-circular>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <!---
        -->
    </div>

      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
        <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>


  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{        
            progress:false,
            order_progress:false,
            order_info_a:false,
            overlay:false,
            drawer: false,
            selectedMenu:'Home',

            orderStatus:[
            {text:'New Order',value:0},
            {text:'Processed Order',value:1},
            {text:'Shipped Order',value:2},
            {text:'Received Order', value:3},
            {text:'Completed Order', value:4}
            ],

            statusX:undefined,

            sideMenu:[
                
            ],

            headers:[
                {text:"Date" , value:"created_at"},
                {text:"Order ID" , value:"order_id"},
                {text:"Payment Type" , value:"payment_type"},
                {text:"Total Amount" , value:"total_amount"},
                {text:"Status" , value:"order_status"},
                {text:"Action" , value:"action"},
            ],
            items:[],
            order_info_product:[],
            order_info_contact:{

            },
            order_id:undefined,
            service_charge:0,
            shipping_fee:0,
            progressp:false,
            current_page:1,
            total_orders:0,
            last_page:1,
        }
    },
    components:{
  
    },
    methods:{
    processOrder(){
      this.progressp=true;
      this.axios({
        url:this.$store.getters.get_api+'processOrder/'+this.order_id,
        method:'get',
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
        console.log(data)
        if(!data.error){
           console.log('')
        }
        this.progressp=false;
      }).catch((error)=>{
        return error;
      })
    },
    cancelOrder(){
      this.progressp=true;
      this.axios({
        url:this.$store.getters.get_api+'cancelOrder/'+this.order_id,
        method:'get',
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
        console.log(data)
        if(!data.error){
           console.log('')
        }
        this.progressp=false;
      }).catch((error)=>{
        return error;
      })
    },
    completeOrder(){
      this.progressp=true;
      this.axios({
        url:this.$store.getters.get_api+'completeOrder/'+this.order_id,
        method:'get',
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
        console.log(data)
        if(!data.error){
           console.log('')
        }
        this.progressp=false;
      }).catch((error)=>{
        return error;
      })
    },
  /*  loadPrevious(){
      if(this.current_page>1){
                this.current_page -=1;
                this.getAllOrders(this.current_page);
            }
    },
    loadNext(){
       if(this.current_page<this.last_page){
                this.current_page +=1;
                this.getAllOrders(this.current_page);
            }
    },*/
    showOrderInfo(order_id){
      this.order_id=order_id
      this.order_progress=true;
      this.order_info_a=true;
      this.axios({
        url:this.$store.getters.get_api+'fetchOrderData/'+order_id,
        method:'get',
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
        console.log(data)
        if(!data.error){
           this.order_info_product = data.products;
           this.order_info_contact = data.contact_info;
           this.service_charge = data.service_charge;
           this.shipping_fee = data.shipping_fee;
           this.order_id = data.order_id
        }
        this.order_progress=false;
      }).catch((error)=>{
        return error;
      })
    },

    getAllOrders(page){
  
        this.$store.dispatch('openProgress');
            this.axios({
              url:this.$store.getters.get_api+'fetchAllOrders/'+page+'/'+this.statusX,
              method:"get",
              headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
              }
            }).then((response)=>{
                const data = response.data;
               // console.log(data)
               //this.$store.commit('set_progress',false)
                if(!data.error){
                   this.items = data.message.data
                   this.total_orders = data.message.meta.total
                   this.last_page = data.message.meta.last_page
                   this.$store.dispatch('closeProgress');
                }
                else{
                    this.$store.commit('set_progress',false);
                    this.$store.commit('set_error',true)
                    this.$store.commit('set_message',data.message);
                }
                
            }).catch((error)=>{
                this.$store.commit('set_progress',false);
                this.$store.commit('set_error',true)
                this.$store.commit('set_message','Network Error');
                throw error
            })
        },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
     
     logout(){
       this.$store.dispatch('openProgress');
      this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.commit("set_token" , undefined);
          this.$store.dispatch('closeProgress');
          this.$router.push("/")
        }
      }).catch((error)=>{
            this.$store.commit('set_progress',false);
            this.$store.commit('set_error',true)
            this.$store.commit('set_message','Network Error');
        return error
      })
     }
    },
    created(){

    },
    mounted(){
       this.statusX = this.orderStatus[0].value
       this.sideMenu=this.$store.getters.get_menu;
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
       this.getAllOrders(1)
    },
    watch:{
       
       statusX(value){
        this.statusX = value;
        this.getAllOrders(1);
       },
       current_page(value){
         this.getAllOrders(value);
       }
        //END OF WATCH
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
.dialog_order_info{
  
}
@media screen and (min-width: 640px) {
  .dialog_order_info{
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
    column-gap: 1em;
  }
  .cinfo{
    width: 200px;
  }

  .cv{
    width:400px;
  }
  .search{
    max-width: 300px;
    margin:2px;
  }
  .he{
    display: inline-flex;
    flex-direction: flex-start;
  }
}
@media screen and (max-width: 620px) {
   .dialog_order_info{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: 1em;
  }
}
.img{
  object-fit: contain;
}
.orange{
  color: orange;
}
.cinfo{
  border-radius: 20px;
  font-family: 'Roboto';
  background: #ffffff;
}
</style>