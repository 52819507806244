<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notification/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
<v-container id="scrolling-techniques-7" class="mt-5 overflow-y-auto">
      <div class="pt-2">
        <span><strong>Frequently Asked Questions</strong></span>
        <v-btn @click.stop="add_form=true" class="ml-4" outlined color="#fe7b00">Add New Faq</v-btn>
      </div>

      <div id="faq-cont" class="mt-3">
        <div v-for="(faq,index) in faqs" :key="index" class="faq">
          <fai icon="question-circle"/>
          <div><strong>Question:</strong><br/>{{faq.question}}</div>
          <div><strong>Answer</strong>
            <br/>
            <span v-html="faq.answer"></span>
          </div>
          <v-btn small rounded color="#fe7b00" @click="updateInit(index)" :style="{position:'absolute',top:'3px',right:'3px'}">Update</v-btn>
        </div>
      </div>
</v-container>

<v-dialog v-model="add_form" persistent :max-width="(innerW >= 640) ? 500 : 330">
  <v-card :style="{position:'relative',padding:'0px .3rem'}">
    <v-btn fab icon @click.stop="add_form=false" small :style="{position:'absolute',top:'-6px'}"><fai icon="close"/></v-btn>
    <v-card-title>Frequently Asked Questions <fai class="pl-1" color="#fe7b00" icon="question-circle"/></v-card-title>
   
    <div>
      <v-text-field v-model="question" class="px-2" label="Question" outlined height="100px"></v-text-field>
    </div>
    <div>
     <!-- <v-text-field v-model="answer" class="px-2"  label="Answer" outlined height="100px"></v-text-field>-->
     <ckeditor :editor="editor" v-model="answer" tag-name="textarea" :config="editorConfig"></ckeditor>

    </div>
    <div class="pb-1 mt-4 d-flex justify-center">
      <v-btn color="#fe7b00" dark @click="save">Save</v-btn>
    </div>
    <div>
      <v-progress-linear v-show="progress" color="#fe7b00" indeterminate></v-progress-linear>
    </div>
  </v-card>
</v-dialog>

<v-dialog v-model="update_form" persistent :max-width="(innerW >= 640) ? 500: 330">
  <v-card :style="{position:'relative',padding:'0px .3rem'}" class="faqcont">
    <v-btn fab icon @click.stop="update_form=false" small :style="{position:'absolute',top:'-6px'}"><fai icon="close"/></v-btn>
    <v-card-title>Frequently Asked Questions <fai class="pl-1" color="#fe7b00" icon="question-circle"/></v-card-title>
    <div>
      <v-text-field v-model="question" class="px-2" label="Question" outlined height="100px"></v-text-field>
    </div>
    <div>
     <!-- <v-text-field v-model="answer" class="px-2"  label="Answer" outlined height="100px"></v-text-field>-->
     <ckeditor :editor="editor" v-model="answer" tag-name="textarea" :config="editorConfig"></ckeditor>

    </div>
    <div class="pb-1 d-flex mt-4 justify-center">
      <v-btn rounded color="#fe7b00" dark @click="update">Update</v-btn>
    </div>
    <div>
      <v-progress-linear v-show="progress" color="#fe7b00" indeterminate></v-progress-linear>
    </div>
  </v-card>
</v-dialog>



      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data:()=>{
        return{
            innerW:0,
            editor:ClassicEditor,
            editorConfig:{
              toolbar:{
                items:['heading','bold','italic','link','|','blockQuote','bulletedList','numberedList','indent','|','undo','redo','selectAll',],
                height:'250px',
                allowedContent:true
              }
            },
            overlay:false,
            drawer: false,
            network_error:false,
            selectedMenu:'Home',
            msg:undefined,
            type:undefined,
            sideMenu:[
              
            ],
            add_form:false,
            progress:false,
            question:null,
            answer:null,
            update_id:undefined,
            update_index:undefined,
            faqs:[
            ],
            update_form:false
        }
    },
    components:{
  
    },
    methods:{
      save(){
        this.$store.dispatch('openProgress')
        if(this.question.length>1){
          const form = new FormData();
          form.append('question',this.question);
          form.append('answer',this.answer);
          this.axios({
            url:this.$store.getters.get_api+"newFaq/",
            method:"post",
            data:form,
            headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
            }
            }).then((res)=>{
            const data = res.data;
            if(!data.error){
                this.$store.dispatch('closeProgress')
                this.getFaqs();
                this.add_form=false;
            }
            this.question=null;
            this.answer = null;
            }).catch((error)=>{
                this.$store.commit('set_progress',false);
                this.$store.commit('set_error',true);
                this.$store.commit('set_message','Network Error');
                  throw error;
            })
        }
      },
      updateInit(index){
        this.update_index = index;
        this.update_id = this.faqs[index].id;
        this.question = this.faqs[index].question;
        this.answer = this.faqs[index].answer;
        this.update_form = true;
      },
      update(){
        this.$store.dispatch('openProgress');
        if(this.question.length>1){
          const form = new FormData();
          form.append('question',this.question);
          form.append('answer',this.answer);
          form.append('id' , this.update_id);
          this.axios({
            url:this.$store.getters.get_api+"updateFaq",
            method:"post",
            data:form,
            headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
            }
            }).then((res)=>{
            const data = res.data;
            if(!data.error){
                this.$store.dispatch('closeProgress');
                this.faqs[this.update_index].question = this.question;
                this.faqs[this.update_index].answer = this.answer;
            }
            this.question=null;
            this.answer = null;
            }).catch((error)=>{
                this.$store.commit('set_progress',false);
                  this.$store.commit('set_error',true);
                  this.$store.commit('set_message','Network Error');
                  throw error;
            })
        }
      },
      switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
      },
      getFaqs(){
            this.$store.dispatch('openProgress')
            this.axios({
            url:this.$store.getters.get_api+"getFaq/1",
            method:"get",
            headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
            }
            }).then((res)=>{
            const data = res.data;
            console.log(data)
            if(!data.error){
                this.$store.dispatch('closeProgress')
                this.faqs = data.message.data
            }
            }).catch((error)=>{
                this.$store.commit('set_progress',false);
                this.$store.commit('set_error',true);
                this.$store.commit('set_message','Network Error');
                throw error;
            })

      }
    },
    created(){

    },
    mounted(){
        this.sideMenu=this.$store.getters.get_menu;
     // this.loadNewNotification(this.$route.params.page)
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
       this.getFaqs()
       this.innerW = innerWidth;
    },
    watch:{
       
        //END OF WATCH
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
@media screen and (min-width: 640px) {
   .card{
    width:300px;
    height:50px;
   }


}
@media screen and (max-width: 620px) {
   .card{
    width:100%;
    height:50px;
   }
 
}
.faq{
  background: #ffffff;
  max-width: 600px;
  padding:5px;
  border-radius: 10px;
  position: relative;
}
#faq-cont{
  display:grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-row-gap: 5px;
  margin-top: 3px;
}
</style>
