<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->

    <div class="container mt-6">
        <div>
            <h4>Total Pending Transactions: {{total_transactions}}</h4>
            </div>
            <v-progress-linear coloe="primary" v-if="load"></v-progress-linear>
             <v-data-table class="" :headers="headers" :items="items">
            <template v-slot:[`item.status`]="{ item }">
                <v-chip v-if="item.status===0" id="pending" dark>Pending</v-chip>
                <v-chip v-if="item.status===1" id="failed" dark>Failed</v-chip>
                <v-chip v-if="item.status===2" id="reversed" dark>Reversed</v-chip>
            </template>

            <template v-slot:[`item.action`]="{item }">
                <v-btn :color="fill" fab icon class="action_btn" small @click="showTxInfo(item.reference)"><fai icon="ellipsis-v"/></v-btn>
            </template>
        </v-data-table>
        <div class="mt-2 d-flex justify-center">
          <v-btn @click.stop="loadPrevious" class="mx-1" small rounded>Previous</v-btn>
           <v-btn @click.stop="loadNext" class="mx-1" small rounded>Next</v-btn>
        </div>
        <!---
        -->
          <v-dialog
        transition="dialog-bottom-transition"
        max-width="500"
        persistent
        v-model="tx_info_a"
        >
            <v-card :style="{position:'relative'}" v-if="selectTx.reference">
                <div class=" pb-3 pa-2" :style="{background:'#fe7b00',color:'white'}"><fai icon="book"/>
                  &nbsp;Transaction Information
                  <v-btn @click="tx_info_a=!tx_info_a" color="white" :style="{position:'absolute',right:'2px',top:'2px',display:'flex',alignItems:'center'}" fab icon small><fai icon="close"/></v-btn>
                </div>
                <div class="dialog_order_info pa-3">

                  <div class="txvc">
                  <strong class="mx-1">Transaction Ref:</strong>
                  <span class="txtx">{{selectTx.reference}}</span>
                  </div>

                  <div class="txvc">
                  <strong class="mx-1">Transfer Code:</strong>
                  <span class="txtx">{{selectTx.transfer_code}}</span>
                  </div>

                  <div class="txvc">
                  <strong class="mx-1">Amount:</strong>
                  <span class="txtx">{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(selectTx.transaction.amount)}}</span>
                  </div>

                  <div class="txvc">
                  <strong class="mx-1">Transaction Type:</strong>
                  <span class="txtx">{{selectTx.transaction.transaction_type}}</span>
                  </div>

                  <div class="txvc">
                  <strong class="mx-1">Transaction Date:</strong>
                  <span class="txtx">{{selectTx.transaction.year+'/'+selectTx.transaction.month+'/'+selectTx.transaction.day}}</span>
                  </div>

                  <v-avatar>
                    <fai icon="user-circle"/>
                  </v-avatar>
                  <div class="txvc">
                  <strong class="mx-1">Name:</strong>
                  <span class="txtx">{{selectTx.transaction.userx.firstname+' '+selectTx.transaction.userx.lastname}}</span>
                  </div>

                  <div class="txvc">
                  <strong class="mx-1">Mobile:</strong>
                  <span class="txtx">{{selectTx.transaction.userx.phone}}</span>
                  </div>

                </div>
                <v-card-actions>
                  <v-btn small dark @click="showUserInfo(selectTx.transaction.userx.id)">User Data</v-btn>
                  <v-btn small color="blue" dark>Cancel</v-btn>

                 <v-btn small v-if="selectTx.transaction.transaction_type==='DEPOSIT'" color="blue" dark>Retry</v-btn>

                </v-card-actions>
                </v-card>
            </v-dialog>
        <!---
        -->
    </div>

      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
          tx_info_a:false,
          load:false,
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
               
            ],
            search:'',
            /**
             * TEST DATA
             */
            current_page:1,
            total_transactions:0,
            last_page:1,
            fill:'#f44336',
            test_image:require("../assets/1.jpg"),
            /**
             * END OF TEST DATA
             */
            user_info_box:false,
            headers:[
                {text:"Reference" , value:"reference"},
                {text:"Transfer Code" , value:"transfer_code"},
                {text:"Status" , value:"status"},
                {text:"Transaction Type" , value:"transaction_type"},
                {text:"Amount" , value:"amount"},
                {text:"Date" , value:"date"},
                {text:"Action" , value:"action"},
            ],
            items:[
                
            ],
            allx:[],
            selectTx:{}
        }
    },
    components:{
  
    },
    methods:{
   showTxInfo(ref){
    for(let i=0; i<this.items.length; i++){
      if(this.items[i].reference===ref){
        this.selectTx = this.allx[i];
        this.tx_info_a=true;
        return;
      }
    }

   },
   showUserInfo(user_id){
            this.$router.push('userData/'+user_id)
        },
    loadPrevious(){
          if(this.current_page>1){
              this.current_page -=1;
              this.getAllTransactions(this.current_page);
          }
      },
      switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
      loadNext(){
          if(this.current_page<this.last_page){
              this.current_page +=1;
              this.getAllTransactions(this.current_page);
          }
      },
        getAllTransactions(page){
          this.$store.dispatch('openProgress');
            this.axios({
              url:this.$store.getters.get_api+'fetchPendingTransaction/'+page,
              method:"get",
              headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((response)=>{
                const data = response.data;
                if(!data.error){
                  this.$store.dispatch('closeProgress');
                  this.allx = data.message.data
                  for(let i=0; i<this.allx.length; i++){
                    this.items.push({
                      reference:this.allx[i].reference,
                      transfer_code:this.allx[i].transfer_code,
                      status:this.allx[i].status,
                      transaction_type:this.allx[i].transaction.transaction_type,
                      amount:this.allx[i].transaction.amount,
                      date:this.allx[i].transaction.year+'/'+this.allx[i].transaction.month+'/'+this.allx[i].transaction.day,
                      action:''
                    })
                  }
                   
                   this.total_transactions = data.message.meta.total
                   this.last_page = data.message.meta.last_page
                }
            }).catch((error)=>{
                this.$store.commit('set_progress',false);
                this.$store.commit('set_error',true);
                this.$store.commit('set_message','Network Error');
                return error
            })
        },
    
     
    },
    created(){

    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
      this.getAllTransactions(1)
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       
        //END OF WATCH
    }
}
</script>
<style scoped>
.txtx{
  color:hsl(0,0%,50%);
  font-family: Open Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  font-size: 15px;
}
*{
    border:0px;
}
#failed{
  background: hsla(0,100%,50%,.3);
  color: hsla(0,100%,20%,1);
}
#pending{
  background: hsla(238,100%,50%,.3);
  color: hsla(238,100%,20%,1);
}
#reversed{
  background: hsla(39,100%,50%,.3);
  color: hsla(39,100%,20%,1);
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
.search-field{
    display: flex;
    justify-content: center;
}

@media screen and (max-width:600px) {
    .search-field{
        width:95%;
    }
    .txvc{
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 30px auto;
    }
    .txtx{
      margin-bottom: .5em;
      padding-left:1em;
    }
}
@media screen and (min-width:640px) {
    .search-field{
        width:300px;
    }
    .txvc{
      display: grid;
      grid-template-columns: 28% auto;
      grid-template-rows: auto;
      grid-column-gap: 2px;
      margin-bottom: 1em;
    }
}
.cardx{
    position:'relative';
}
.container{
    min-width:100%;
    min-height:100%;
}
.close{
    position:absolute;
    right:2px;
    top:2px;
}
.sh{
    box-shadow:  2px 1px 2px rgba(255,255,255,.3),
             -2px -1px 2px rgba(0,0,0,.5);
    color:#ffffff;
    margin:2px;
    font-family:sans-serif;
    font-weight:bold;

}
.cardx{

}
</style>