<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
      <div class="main-cont">
        
        <div class="col1">
           <v-alert class="mt-2" prominent type="info">
            Create New Promotions
            </v-alert>

            <!--<v-chip class="mb-2" outlined>Select Desktop Image</v-chip>
            <v-card height="150px" class="card" ripple @click="selectimg(1)">
              <fai icon="image" class="img-icon" v-if="d_img===undefined"/>
              
              <img src="" id="d_img" class="image">
              <input type="file" id="dmg" class="d-none" accept="image/*">
            </v-card>-->

            <v-chip class="mb-2 mt-2" outlined>Select Promotion Image</v-chip>
            <v-card height="150px" class="card" ripple @click="selectimg()">
              <fai icon="image" class="img-icon" v-if="m_img===undefined"/>
              
              <img src="" id="m_img" class="image">
              <input type="file" id="mmg" class="d-none" accept="image/*">
            </v-card>

            <v-text-field v-model="duration" solo flat placeholder="Duration" dense class="mt-3"></v-text-field>

            <v-text-field v-model="promotion_link" solo flat placeholder="Promotion Link" dense class="mt-1"></v-text-field>
            <div class="d-flex justify-center"><v-btn @click.stop="submit" rounded>Submit</v-btn></div>
        </div>


        <div class="col2">
          <v-text-field @keydown.enter="search" v-model="search_promotion" solo flat rounded placeholder="Promotion Code">
            <template v-slot:append>
               <fai icon="search"/>
            </template>
          </v-text-field>

           <v-data-table class="tabledata" :headers="headers" :items="promotions">

            <template v-slot:[`item.user`]="{item }">
                <span v-if="parseInt(item.user)!==0" :style="{color:'green'}">User</span>
                <span v-if="parseInt(item.user)===0" :style="{color:'blue'}">Admin</span>
            </template>

            <template v-slot:[`item.flag`]="{ item }">
                <v-switch
                :color="(item.flag!==1)?'primary':'red'"
                dense
                value
                @change='toggleFlag(item.promotion_code)'
                :input-value="(item.flag!==1)?true:false">
                </v-switch>

            </template>

            <template v-slot:[`item.view`]="{item }">
                <v-btn fab icon class="action_btn" small @click="showPromotionInfo(item.id)"><fai icon="image"/></v-btn>

                <v-btn fab icon class="action_btn" small @click="editPromotion(item.id)"><fai icon="edit"/></v-btn>
                
                <v-btn fab icon class="action_btn" small @click="deletePromotion(item.id)"><fai icon="trash-can"/></v-btn>
                
            </template>
        </v-data-table>

        <div class="text-center">
          <v-pagination
            v-model="current_page"
            :length="last_page"
            :total-visible="7"
          ></v-pagination>
        </div>
        <!--<div class="pagination" :style="{marginTop:'.4em',display:'flex',justifyContent:'center'}">
                   <v-btn  @click="loadPreviousPromotions()" fab small class="mx-1"><fai icon="angle-left"/></v-btn>
                   <v-btn @click="loadNextPromotions()" fab small class="mx-1"><fai icon="angle-right"/></v-btn>
               </div>-->
        </div>

        <v-dialog v-model="dialog" width="350px">
          <v-card class="pa-2">
           <!-- <v-chip class="mb-2" outlined>Desktop Image</v-chip>
            <v-img height="150px" :src="$store.getters.get_api+'fetchPromotionImages/'+dsk_img"></v-img>-->
            <v-chip class="mb-2 mt-2" outlined>Mobile Image</v-chip>
            <v-img height="150px" :src="$store.getters.get_api+'fetchPromotionImages/'+mb_img"></v-img>
          </v-card>
        </v-dialog>


        <v-dialog v-model="open_edit_page" width="350px">
          <v-card class="pa-2" :style="{position:'relative'}">

            <div class="section">
              <div  class="mb-2 caption">Update Image</div>
              <v-file-input 
                v-model="image_update" 
                label="Promotion image"
                outlined
                dense>
                  <template v-slot:prepend>
                    <fai icon="file"/>
                  </template>
                </v-file-input>
               <span id="update_image_name"></span>
               <v-btn @click="updateImage" class="primary" dark>Update</v-btn>
            </div>

            <div class="section">
              <div  class="mb-2 caption">Update Duration</div>
              <v-text-field 
                type="num"
                dense
                outlined
                label="Duration"
                v-model="duration_update"
                ></v-text-field>
                <div>
                  <v-btn @click="updateDays('ADD')" rounded small class="primary" dark>Add Days</v-btn>
                  &nbsp;
                  <v-btn @click="updateDays('REMOVE')" rounded small class="primary" dark>Remove Days</v-btn>
                </div>
            </div>


            <div class="section">
              <div  class="mb-2 caption">Update link</div>
              <v-text-field 
                type="text"
                dense
                outlined
                label="Promotion link"
                v-model="link_update"
                ></v-text-field>
                <v-btn @click="updateLink()" rounded small class="primary" dark>Update link</v-btn>
            </div>

            <v-card-actions>
              <v-btn color="red" dark @click="closeEdit">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </div>
      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
       <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
            dsk_img:'',
            mb_img:'',
            dialog:false,
            flagStatusLoader:false,
            progress:false,
            promotion_link:'',
            duration:'',
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
               
            ],
            d_img:undefined,
            m_img:undefined,
            current_page:1,
            last_page:1,
            total_promotions:0,
            promotions:[],
            headers:[
                {text:"Source" , value:"user"},
                {text:"Promotion Code" , value:"promotion_code"},
                {text:"Link" , value:"promotion_link"},
                {text:"Duration" , value:"duration"},
                {text:"Flag" , value:"flag"},
                {text:"View" , value:"view"},
            ],
            items:[],
            search_promotion:'',
            ev1:undefined,
            ev2:undefined,
            promotion_for_edit:{},
            open_edit_page:false,

            link_update:undefined,
            duration_update:undefined,
            image_update:undefined
        }
    },
    components:{
 
    },
    methods:{
    updateLink(){
      if(this.link_update){
        let form = new FormData()
        form.append('link', this.link_update)
        form.append('id', this.promotion_for_edit.id);
        this.openProgress()
        this.axios({
          url:this.$store.getters.get_api+'admin_update_promotions_link',
          method:'post',
          data:form,
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
        }).then((res)=>{
          const data = res.data;
          if(!data.error){
           this.fetchPromotions(this.current_page);
          }
         // this.progressx=false;
        }).catch((error)=>{
           this.$store.commit('set_message','A network error occured');
            this.$store.commit('set_error',true);
          return error;
        })
      }
    },
    updateDays(string){
      if(this.duration_update){
        let form = new FormData()
        form.append('duration', this.duration_update)
        form.append('date_operation', string)
        form.append('id', this.promotion_for_edit.id);
        this.openProgress()
        this.axios({
          url:this.$store.getters.get_api+'admin_update_promotions_time',
          method:'post',
          data:form,
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
        }).then((res)=>{
          const data = res.data;
          if(!data.error){
           this.fetchPromotions(this.current_page);
          }
         // this.progressx=false;
        }).catch((error)=>{
           this.$store.commit('set_message','A network error occured');
            this.$store.commit('set_error',true);
          return error;
        })
      }
    },
    closeEdit(){
      this.ev1=null;
      this.open_edit_page = false;
    },
    updateImage(){
      if(this.link_update){
        let form = new FormData()
        form.append('promotion_banner_mobile_update', this.image_update)
        form.append('id', this.promotion_for_edit.id);
        this.openProgress()
        this.axios({
          url:this.$store.getters.get_api+'update_promotion_image',
          method:'post',
          data:form,
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
        }).then((res)=>{
          const data = res.data;
          if(!data.error){
           this.fetchPromotions(this.current_page);
          }
         // this.progressx=false;
        }).catch((error)=>{
           this.$store.commit('set_message','A network error occured');
            this.$store.commit('set_error',true);
          return error;
        })
      }
    },
    editPromotion(id){
      let index;
      for(let i=0; i<this.promotions.length; i++){
        if(this.promotions[i].id===id){
          index = i;
          break;
        }
      }
      this.promotion_for_edit = this.promotions[index];
      this.open_edit_page=true;
      this.link_update = this.promotion_for_edit.promotion_link;
      //this.duration_update = this.promotion_for_edit.duration;

    },
    search(){
     this.openProgress()
      this.axios({
        url:this.$store.getters.get_api+'searchPromotions/'+this.search_promotion,
        method:'get',
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.dispatch('closeProgress')
          this.promotions=data.message;
        }
       // this.progressx=false;
      }).catch((error)=>{
         this.$store.commit('set_message','A network error occured');
          this.$store.commit('set_error',true);
        return error;
      })
    },
    deletePromotion(id){
      this.openProgress()
      this.axios({
        url:this.$store.getters.get_api+'deletePromotion/'+id,
        method:'get',
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.fetchPromotions(this.current_page);
          //this.$store.dispatch('closeProgress')
        }
       // this.progressx=false;
      }).catch((error)=>{
         this.$store.commit('set_message','A network error occured');
          this.$store.commit('set_error',true);
        return error;
      })
    },
    showPromotionInfo(id){
      var index;
      this.promotions.forEach((e,indexx)=>{
        if(e.id===id){
          index = indexx
        }
      });
      const data = this.promotions[index];
      this.mb_img = data.promotion_banner_mobile;
      this.dsk_img = data.promotion_banner_desktop;
      this.dialog=true;
    },
    toggleFlag(item){
      this.openProgress();
      this.axios({
                url:this.$store.getters.get_api+'flagPromotion/'+item,
                method:"get",
                headers:{
                    "Access-Control-Allow-Origin":"*",
                    'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((response)=>{
                const data = response.data
                if(!data.error){
                   this.$store.dispatch('closeProgress')
                }
            }).catch((error)=>{
               this.$store.commit('set_message','A network error occured');
          this.$store.commit('set_error',true);
                return error
            })
    },
    fetchPromotions(page){
      this.openProgress();
            this.axios({
                url:this.$store.getters.get_api+'getPromotionAdmins/'+page,
                method:"get",
                headers:{
                    "Access-Control-Allow-Origin":"*"
                }
            }).then((response)=>{
                const data = response.data
                if(!data.error){
                    this.$store.dispatch('closeProgress')
                    this.promotions = data.message.data
                    this.total_promotions = data.message.meta.total   
                }
            }).catch((error)=>{
               this.$store.commit('set_message','A network error occured');
          this.$store.commit('set_error',true);
                return error
            })
    },
    /*loadPreviousPromotions(){
        if(this.current_page>1){
            this.current_page -=1;
            this.fetchPromotions(this.current_page);
        }
    },
    loadNextPromotions(){
      if(this.current_page<this.total_promotions){
          this.current_page +=1;
          this.fetchPromotions(this.current_page);
      }
    },*/
    openProgress(){
      this.$store.dispatch('openProgress')
     },
    submit(){
      this.openProgress();
      const form = new FormData();
      form.append('duration' , this.duration);
      form.append('promotion_link' ,this.promotion_link);
      //form.append('promotion_banner_desktop' , document.getElementById('dmg').files[0]);
      form.append('promotion_banner_mobile' , document.getElementById('mmg').files[0]);
      this.axios({
        url:this.$store.getters.get_api+'adminAddPromotions',
        method:'post',
        data:form,
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
         this.fetchPromotions(1)
        }
      }).catch((error)=>{
        this.$store.commit('set_progress',false)
         this.$store.commit('set_message','A network error occured');
          this.$store.commit('set_error',true);
        return error;
      })
    },
    selectimg(){
      document.getElementById('mmg').click()
    },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
    
    },
    created(){
      this.fetchPromotions(1)
    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
      //this.totalNotice();
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
       

       this.ev2=document.getElementById('mmg').addEventListener('change',()=>{
        var file = document.getElementById('mmg').files[0];
        var reader = new FileReader()
        reader.onload = function(e){
          this.m_img=1;
          /*var image = new Image()
          image.src = e.target.result;
          image.classList.add('image')
          document.getElementsByClassName('card')[1].innerHTML=image;*/
          document.getElementById('m_img').src=e.target.result;
        }
        reader.readAsDataURL(file)
       })  

    },
    destroyed(){
      this.ev2=null;
      this.ev1=null;
    },
    watch:{
       current_page(value){
         this.fetchPromotions(value);
       }
        //END OF WATCH
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.col2{
  margin-top:1em;
}
.section{
  border:solid 1.2px gray;
  border-radius: 5px;
  padding:.5rem .5rem;
  margin:.3rem .1rem;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
@media screen and (min-width: 640px) {
   .main-cont{
    display:flex;
    flex-flow:row wrap;
    justify-content: space-between;
   }
   .tabledata{

   }
}
@media screen and (max-width: 620px) {
   .main-cont{

   }
   .tabledata{
    max-width: 350px;
   }
}
.main-cont{
  margin-top:2em;
}
.img-icon{
  margin:17% 40%;
  font-size: 50px;
}
.card{
  position:relative;
}
.image{
  position:absolute;
  z-index:7;
  width:100%;
  height:100%;
  left:0px;
}

</style>