<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
    <div class="mt-8">
        <div class="pa-1" :style="{display:'flex',flexDirection:'row'}">
            <div :style="{width:'150px'}"><v-select solo dense flat v-model="options_select" label="Sort By Type" :items="options"></v-select></div>

            <div class="ml-2">
                <v-text-field @keydown.enter="SearchTransaction()" v-model="search_select" solo dense flat label="Transaction ID/Ref" hint="Enter Transaction ID or Reference">
                    <template v-slot:default><fai icon="search"/></template>
                </v-text-field>
            </div>
        </div>

        <v-progress-linear indeterminate v-show="loading"></v-progress-linear>

        <v-data-table class="" :headers="headers" :items="items">
            
            <template v-slot:[`item.more`]="{item }">
                <v-btn icon class="action_btn" small @click="loadUserData(item.user)"><fai icon="ellipsis-v"/></v-btn>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-chip v-if="item.status===1" color="#4caf50" dark>Completed</v-chip>
                <v-chip v-if="item.status!==1" color="#dd2c00" dark>Pending</v-chip>
            </template>

        </v-data-table>
        
        <div class="text-center">
          <v-pagination
            v-model="current_page"
            :length="last_page"
            :total-visible="7"
          ></v-pagination>
        </div>
         <!--<div class="pagination mt-2" :style="{display:'flex',justifyContent:'center'}">
                   <v-btn  @click="loadPreviousProducts()" small class="mx-1"><fai icon="angle-left"/></v-btn>
                   <v-btn @click="loadNextProducts()" small class="mx-1"><fai icon="angle-right"/></v-btn>
               </div> -->
    </div>
      <!--
  END OF WEB PAGE
      -->
    </v-container>


      <v-dialog v-model="user_data" width="300px">
        <v-card dark color="#282c2e">
          <v-img :src="user.profile_picture" height="150"></v-img>
          <v-card-text class="pa-2">
            <div><span><strong><fai icon="user-circle"/>&nbsp;Name</strong>&nbsp;{{user.firstname}}&nbsp;{{user.lastname}}</span></div>

            <div><span><strong><fai icon="envelope-open"/>&nbsp;Email</strong>&nbsp;{{user.email}}</span></div>

            <div><span><strong><fai icon="phone"/>&nbsp;Phone</strong>&nbsp;{{user.phone}}</span></div>

          </v-card-text>
          <div class="px-6"><v-progress-linear indeterminate v-show="progressx"></v-progress-linear></div>
          <v-card-actions class="pa-2">
            <v-btn small rounded outlined color="success">Complete Request</v-btn>
            <v-btn small rounded outlined color="primary" @click.stop="showUserInfo(user.id)">User Profile</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
            progressx:false,
            user_data:false,
            user:{  firstname:"",
                    lastname:"",
                    address:'',
                    phone:'',
                    email:'',
                    wallet_id:'',
                    status:1,
                    id:1,
                    profile_picture:require("../assets/1.jpg"),
                    nin:'',
                    gender:""
                  },
            loading:false,
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
               
            ],
            select_trx:{
                type:'',
                user:'',
                total:0
            },
            progress:false,
            search_select:'',
            options_select:'',
            items:[],
            transactions:[],
            transaction_type:'COMPLETED',//PENDING , COMPLETED , SEARCH
            total_transactions:0,
            current_page:1,
            last_page:1,
            headers:[
                {text:"Date" , value:"date"},
                {text:"Transaction Type" , value:"transaction_type"},
                {text:"Amount" , value:"amount"},
                {text:"Transaction Reference" , value:"transaction_ref"},
                {text:"Transaction ID" , value:"transaction_id"},
                {text:"Status" , value:"status"},
                {text:"More" , value:"more"},
            ],
            options:[{text:'PENDING' , value:'PENDING'},{text:'COMPLETED' , value:'COMPLETED'}]
        }
    },
    components:{
  
    },
    methods:{
     loadUserData(user){
            this.progressx=true;
            this.user_data=true;
            this.axios({
                url: this.$store.getters.get_api+'fetchUserDataAdmin/'+user,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((response)=>{
                const data = response.data;
                console.log(data)
                if(!data.error){
                    this.user=data.message;     
                    this.progressx=false;
                }
            }).catch((error)=>{
                this.progressx=false
                //this.network_error=true
                return error
            })
        },
      showUserInfo(user_id){
            this.$router.push('userData/'+user_id)
        },
    SearchTransaction(){
      this.axios({
        url:this.$store.getters.get_api+'searchTransactionByRef/'+this.search_select,
        method:'get',
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data
        if(!data.error){
          this.items = new Array();
          this.transactions= data.message
          data.message.forEach((e,index)=>{
            this.items.push({
              transaction_type:e.transaction_type,
              transaction_id:e.transaction_id,
              transaction_ref:e.transaction_ref,
              amount:new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(e.amount),
              date:e.year+'/'+e.month+'/'+e.day,
              user:e.user,
              status:e.status,
              index:index
            })
            console.log(this.items)
          })
       }
      }).catch((error)=>{
        return error;
      });
    },
      /*  loadPreviousProducts(){
            if(this.current_page>1){
                this.current_page -=1;
                this.fetchTransactions(this.current_page , this.transaction_type);
            }
        },
        loadNextProducts(){
            if(this.current_page<this.total_transactions){
                this.current_page +=1;
               this.fetchTransactions(this.current_page , this.transaction_type);
            }
        },*/
      
        fetchTransactions(page,type){
            let url = '';
            switch (type) {
                case 'PENDING':
                    url = this.$store.getters.get_api+'fetchAllPendingTransactions/'+page
                    break;
                case 'COMPLETED':
                    url = this.$store.getters.get_api+'fetchAllCompletedTransactions/'+page
                    break;
            }
           this.$store.dispatch('openProgress');
            //after checking
            this.axios({
                url : url , 
                method:'get',
                headers:{
                  'Access-Control-Allow-Origin':'*',
                  'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((res)=>{
                const data = res.data;
                //console.log(data);
                if(!data.error){
                  this.$store.dispatch('closeProgress')
                    this.items = new Array();
                    this.transactions= data.message.data
                  data.message.data.forEach((e,index)=>{
                    this.items.push({
                        transaction_type:e.transaction_type,
                        transaction_id:e.transaction_id,
                        transaction_ref:e.transaction_ref,
                        amount:new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(e.amount),
                        date:e.year+'/'+e.month+'/'+e.day,
                        user:e.user,
                        status:e.status,
                        index:index
                    })
                  })

                  this.total_transactions = data.message.meta.total;
                  //this.items = data..data;
                  //console.log(this.items)
                }else{
                  this.$store.commit('error',true);
                  this.$store.commit('progress',false);
                  this.$store.commit('message','Error loading transactions')
                } 
            }).catch((error)=>{
                this.$store.commit('error',true);
                  this.$store.commit('progress',false);
                  this.$store.commit('message','Network Error')
                throw error;
            })
        },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
     
     logout(){
      
      this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.commit("set_token" , undefined);
          this.$router.push("/")
        }
      }).catch((error)=>{
        return error
      })
     }
    },
    created(){

    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
       this.fetchTransactions(1 , 'COMPLETED');
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
      options_select(value){
           this.transaction_type=value;
           this.fetchTransactions(this.current_page , this.transaction_type);
        },
        current_page(value){
          this.fetchTransactions(value , this.transaction_type);
        }
        //END OF WATCH
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
@media screen and (min-width: 600px) {
   
}
@media screen and (max-width: 600px) {
   
}

</style>