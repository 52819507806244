<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
 <div class="mt-8 pa-2 card">
  <v-alert class="mt-2" prominent type="info">
        Product Information
    </v-alert>

      <div class="images" :style="{marginBotton:'3px'}">
          <div v-for="(img , ind) in product_images" :key="ind" class="img_cont">
              <v-img :style="{borderRadius:'5px',height:'250px' , width:'250px'}" class="mx-1 mt-1 imgx" :src="$store.getters.get_api+'fetchProductPic/'+img"></v-img>
          </div>
      </div>
          <div :style="{marginTop:'5px',marginBottom : '3px'}"><span class="chip"><strong>Name &nbsp;</strong>{{product_information.product_name}}</span></div>
          <div><v-chip class="chip"><strong>Price &nbsp; </strong>{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(product_information.price)}}</v-chip></div>
          <v-card-text>
              <fai class="mx-2 mt-3" icon="book"/>Product Description
          </v-card-text>
         <div class="desc" :style="{padding:'6px',}">{{product_information.description}}</div>
      <v-card-text>
              <fai class="mx-2 mt-3" icon="user-circle"/>Product Seller
          </v-card-text>
      <v-container class="pa-4">
          
      <div class="contx">
          
          <div>
              <div class="details">
                   <v-btn class="mt-2" rounded dark :color="fill" @click="viewSeller">More About The Seller&nbsp;<fai icon="user-circle"/></v-btn>
              </div>
          </div>
      </div>
  </v-container>
      </div>
      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
            fill:'#f44336',
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
                
            ],
            product_information:{},
            seller:{},
            product_images:[],
            test_image:[
                require('../assets/5.jpg'),
                require("../assets/3.jpg"),
                require("../assets/2.jpg"),
                require("../assets/1.jpg")
                ],
        }
    },
    components:{
  
    },
    methods:{
    viewSeller(){
      this.$router.push('/userData/'+this.product_information.user)
    },
    loadProductData(){
            const product = this.$route.params.slug;
            //console.log(user)
            this.network_error=false;
            this.axios({
                url: this.$store.getters.get_api+'fetchProductData/'+product,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((response)=>{
                const data = response.data;
                console.log(data)
                if(!data.error){
                    this.product_information=data.product_data;
                    this.seller = data.seller;
                    this.product_images = this.product_information.images.split(':')
                    this.progress=false;
                    this.overlay=false
                }
            }).catch((error)=>{
                this.progress=false
                this.network_error=true
                return error
            })
        },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
     
     logout(){
      
      this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.commit("set_token" , undefined);
          this.$router.push("/")
        }
      }).catch((error)=>{
        return error
      })
     }
    },
    created(){

    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
      this.loadProductData();
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       
        //END OF WATCH
    }
}
</script>
<style scoped>
@font-face {
font-family: "raleway";
src: url(../assets/Raleway-Bold.ttf) format("truetype"),
/*url("CustomFont.otf") format("opentype"),
url("CustomFont.svg#filename") format("svg");*/
}
.card{
  font-family: 'raleway';
}
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
}
.icon-1{
    font-size: 50px;
}

.images{
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}
@media screen and (min-width: 600px) {
   .contx{

   }
}
@media screen and (max-width: 600px) {
   .contx{
    display:flex;
    justify-content: center;
    flex-flow:row wrap;
   }
}

</style>