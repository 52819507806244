<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
<div class="container mt-6">

        <div class="searchContainer">
        <div class="search-field">
            <v-text-field @keypress.enter="searchAllProducts" v-model="search_product" solo text rounded placeholder="search product" hint="enter product name">
                <template v-slot:append>
                    <fai icon="search"/>
                </template>
            </v-text-field>
            <strong v-if="no_search_results" :style="{fontFamily:'sans-serif'}">No results found for <i :style="{fontStyle:'italic'}">{{search_product}}</i></strong>
            </div>

            <div class="search-field">
            <v-select rounded solo text :items="cats_selected" v-model="selected_category" placeholder="select product category"></v-select>
            </div>
        </div>
        
        <div class="data">
            <div>
                <div class="products">
                <v-card :style="{borderRadius:'10px'}" maxHeight="250px" minHeight="250px" class="product pb-2" v-for="(item,index) in products" :key="index">
                <v-btn @click.stop="productInfo(index)" class="more_data_icon"  small fab><fai icon="ellipsis-v"/></v-btn>
                <v-btn @click.stop="openDelete(index)" class="more_data_icon1" small fab><fai icon="trash-can"/></v-btn>
                <v-chip dark :color="fill" class="price_data_icon">
                  {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(item.price)}}
                </v-chip>

                <v-img  class="img" :src="$store.getters.get_api+'fetchProductPic/'+item.preview" height="150"></v-img>
                <v-card-text>
                    {{item.product_name}}
                </v-card-text>
                <!--<v-card-actions><span>{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(item.price)}}</span></v-card-actions>-->
                </v-card>
               </div>

                <div class="text-center">
                <v-pagination
                    v-model="current_page"
                    :length="last_page"
                    :total-visible="7"
                ></v-pagination>
                </div>
              <!--  <div class="pagination" :style="{display:'flex',justifyContent:'center'}">
                   <v-btn  @click="loadPreviousProducts()" fab small class="mx-1"><fai icon="angle-left"/></v-btn>
                   <v-btn @click="loadNextProducts()" fab small class="mx-1"><fai icon="angle-right"/></v-btn>
               </div>   -->

               
            </div>
            <div class="settings">
                <v-chip><fai icon="plus"/>&nbsp;Add new Category</v-chip>
                <div><v-text-field v-model="new_category_name"  class="mt-2" dense solo flat placeholder="Category Name"></v-text-field></div>
                <div :style="{display:'flex',justifyContent:'center',flexDirection:'column'}">
                    <v-btn solo @click="selectCategoryImage"><fai icon="image"/>&nbsp;Select Category Image</v-btn>
                    {{category_image_notice}}
                    <input  :style="{visibility:'hidden'}" type="file" accept="image/*" id="new_category_image" @change="selectionListener">
                    <v-btn @click="submitCategory" dark rounded :color="theme" class="mt-2" width="50px"><fai icon="plus"/>&nbsp;Add</v-btn>
                </div>
                <div class="registered_categories">
                    <v-chip v-for="(item , index) in product_categories" :key="index" class="mx-2 mt-2">
                        <template v-slot:default>
                         <v-avatar size="90" :rounded="false">
                            <v-img :src="$store.getters.get_api+'fetchCategoriesPic/'+item.image"></v-img>
                        </v-avatar>
                        &nbsp;
                        {{item.name}}
                        <v-btn icon fab @click.stop="editCategory(index)"><fai icon="edit"/></v-btn>

                        <v-btn icon fab @click.stop="deleteCategoryModal(index)"><fai icon="trash-can"/></v-btn>
                        </template>
                    </v-chip>
                </div>

                <v-chip class="mt-5"><fai icon="plus"/>&nbsp;Add new Sub-Category</v-chip>
                <div><v-text-field  v-model="new_sub_category_name" class="mt-2" dense solo flat placeholder="Sub Category Name"></v-text-field></div>

                <div><v-select v-model="sub_cat_cat"  dense solo text label="Select Category" :items="cats_selected"></v-select></div>

                <div><v-btn dark @click="addMeta()" class="mb-2"  rounded :color="theme">Add Meta Data &nbsp;<fai icon="plus"/></v-btn>&nbsp;<span :style="{fontFamily:'sans-serif',fontStyle:'italic'}">optional</span></div>

                <div :style="{maxHeight:'300px',overflowY:'scroll'}">
                <div class="meta pa-3" v-for="(item , index) in meta" :key="index">
                    <v-text-field solo flat dense type="text" v-model="item.name" placeholder="meta data name"></v-text-field>
                    <v-btn fab icon small @click="removeMeta(index)"><fai icon="trash-can"/></v-btn>
                </div>
                </div>
                <div :style="{display:'flex',justifyContent:'center',flexDirection:'column'}">
                    <v-btn solo @click="selectSubCategoryImage"><fai icon="image"/>&nbsp;Sub-Category Image</v-btn>
                    {{sub_category_image_notice}}
                    <input  :style="{visibility:'hidden'}" type="file" accept="image/*" id="new_sub_category_image" @change="selectionListener">
                    <v-btn   @click="submitSubcategory" :color="theme" class="mt-2" width="50px" rounded dark><fai icon="plus"/>&nbsp;Add</v-btn>
                </div>
                 <div class="registered_subcategories">
                    <v-chip v-for="(item , index) in product_subcategories" :key="index" class="mx-2 mt-2">
                        <template v-slot:default>
                             <v-avatar size="90" :rounded="false">
                                <v-img :src="$store.getters.get_api+'fetchCategoriesPic/'+item.image"></v-img>
                            </v-avatar>
                            &nbsp;
                            {{item.name}}
                            <v-btn icon fab @click.stop="editSubCategory(index)"><fai icon="edit"/></v-btn>
                            <v-btn icon fab @click.stop="deleteSubCategoryModal(index)"><fai icon="trash-can"/></v-btn>
                        </template>
                    </v-chip>
                </div>
            </div>
        </div>

        <v-overlay v-show="overlay">
           <v-progress-circular v-show="progress" indeterminate :color="fill"></v-progress-circular>
           <v-alert :type="alert_type" shaped v-show="alert_box">
               {{alert_message}}
               <template v-slot:close><v-btn icon @click.stop="hideOverlay"><fai icon="close"/></v-btn></template>
               </v-alert>
        </v-overlay>

        <v-dialog
        transition="dialog-bottom-transition"
        max-width="300"
        v-model="edit"
        >
            <v-card class="pa-2">
                <div class=" mb-1 d-flex justify-center"><v-card-title><fai icon="edit"/>Edit Category</v-card-title></div>
                <v-text-field outlined v-model="new_name" label="category name"></v-text-field>
                <div class=" mb-1 d-flex justify-center"><v-btn rounded @click="clickEditImgSelect">Edit Category Image</v-btn></div>
                <input class="d-none" @change="editCatImg" type="file" accept="image/*" id="edit_cat_img" />
                <div v-if="edit_alert"><v-alert type="info">{{edit_feedback}}</v-alert></div>
                <v-card-actions @click="submitEdited" class="d-flex justify-center"><v-btn outlined>
                    Submit
                </v-btn></v-card-actions>
                <v-progress-linear indeterminate v-show="edit_progress"></v-progress-linear>
            </v-card>
        </v-dialog>


        <v-dialog
        transition="dialog-bottom-transition"
        max-width="300"
        v-model="edit_sub"
        >
            <v-card class="pa-2">
                <div class=" mb-1 d-flex justify-center"><v-card-title><fai icon="edit"/>Edit SubCategory</v-card-title></div>
                <v-text-field outlined v-model="new_name_sub" label="Subcategory name"></v-text-field>
                <div class=" mb-1 d-flex justify-center"><v-btn rounded @click="clickEditImgSelectSub">Edit Category Image</v-btn></div>
                <input class="d-none" @change="editCatImgSub" type="file" accept="image/*" id="edit_cat_img_sub" />
                <div v-if="edit_alert_sub"><v-alert type="info">{{edit_feedback_sub}}</v-alert></div>

                <div>
                <v-btn dark small @click="addMetasubedit()" class="mb-2"  rounded :color="theme">Add Meta Data &nbsp;<fai icon="plus"/>
                </v-btn>&nbsp;<span :style="{fontFamily:'sans-serif',fontStyle:'italic'}">optional</span>
                </div>

                <div :style="{maxHeight:'200px',overflowY:'scroll'}">
                <div :style="{display:'inline-flex'}" class="pa-1" v-for="(item , index) in metasubedit" :key="index">
                    <v-text-field outlined dense type="text" v-model="item.name" placeholder="meta data name"></v-text-field>
                    <v-btn fab icon small @click="removeMetasubedit(index)"><fai icon="trash-can"/></v-btn>
                </div>
                </div>

                <div class="cddd">
                    <div class="px-2" :style="{backgroundColor:'rgba(0,0,0,.1)',borderRadius:'10px',marginBottom:'2px',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}" v-for="(metas,index) in subcategories_metadata" :key="index">
                        <div>{{metas.name}}</div>
                        <div>
                        <v-btn @click.stop="editMetat(index)" icon fab small><fai icon="edit"/></v-btn>
                        <v-btn @click.stop="removeMetax(metas.id,index)" icon fab small><fai icon="trash-can"/></v-btn>
                        </div>
                    </div>
                </div>

                <v-card-actions @click="submitEditedSub" class="d-flex justify-center"><v-btn outlined>
                    Submit
                </v-btn></v-card-actions>
                <v-progress-linear indeterminate v-show="edit_progress_sub"></v-progress-linear>
            </v-card>
        </v-dialog>


        <!---
        DELETE PROMPT FOR CATEGORIES
        -->
        <v-dialog
        transition="dialog-bottom-transition"
        persistent
        max-width="250"
        v-model="delete_prompt_category"
        >
            <v-card class="pa-2 prompt pt-6">
                Do you want to remove <strong>{{cat_name_x}}</strong> category?
                <v-card-actions class="d-flex justify-space-between px-3 mt-4">
                <v-btn dark color="green" @click.stop="delete_prompt_category=!delete_prompt_category">No</v-btn>
                <v-btn dark color="red" @click.stop="removeCategory()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!---
        -->

        <!---
        DELETE PROMPT FOR SUBCATEGORIES
        -->
        <v-dialog
        transition="dialog-bottom-transition"
        persistent
        max-width="250"
        v-model="delete_prompt_subcategory"
        >
            <v-card class="pa-2 prompt pt-6">
                Do you want to remove <strong>{{subcat_name_x}}</strong> subcategory?
                <v-card-actions class="d-flex justify-space-between px-3 mt-4">
                <v-btn dark color="green" @click.stop="delete_prompt_subcategory=!delete_prompt_subcategory">No</v-btn>
                <v-btn dark color="red" @click.stop="removeSubCategory()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!---
        -->

        <!---
        DELETE PROMPT FOR product
        -->
        <v-dialog
        transition="dialog-bottom-transition"
        persistent
        max-width="250"
        v-model="delete_prompt_product"
        >
            <v-card class="pa-2 prompt pt-6" v-if="delete_prompt_product">
                Do you want to remove <strong>{{products[product_to_delete].product_name}}</strong>?
                <v-card-actions class="d-flex justify-space-between px-3 mt-4">
                <v-btn dark color="green" @click.stop="delete_prompt_product=!delete_prompt_product">No</v-btn>
                <v-btn dark color="red" @click.stop="deleteProduct()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!---
        -->

        <!--
        DIALOG FOR META DATA EDIT
        -->
        <v-dialog
        transition="dialog-bottom-transition"
        persistent
        max-width="450"
        v-model="edit_meta_data"
        >
            <v-card class="pa-2" v-if="metadataforedit">
                <div class="pb-2"><v-btn @click.stop="closeMetaEdit" fab small color="primary" :style="{float:'right'}"><fai icon="close"/></v-btn></div>
                <br/>
                <div class="d-flex justify-center">
                Edit&nbsp;<strong>{{metadataforedit.name}}</strong>&nbsp;MetaData
                </div>

                <div>
                    <v-select dense outlined label="Meta Type" :items="edit_meta_type" v-model="meta_type"></v-select>
                </div>

                <div v-if="meta_type=='OPTIONS'">
                   <v-textarea dense outlined v-model="meta_options" placeholder="data1,data2,data3"></v-textarea>
                </div>

                <v-card-actions class="d-flex justify-space-between px-3 mt-4">
                <v-btn color="primary" @click.stop="updateMetadata">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--
        -->
    </div>


      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
        <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
            meta_options:undefined,
            meta_type:'TEXT',
            edit_meta_type:[{text:'Text', value:'TEXT'},{text:'Options', value:'OPTIONS'}],
            edit_meta_data:false,
            metadataforedit:undefined,
            delete_prompt_category:false,
            delete_prompt_subcategory:false,
            cat_name_x:undefined,
            subcat_name_x:undefined,
            delete_index:undefined,

            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
               
            ],
            edit_alert:false,
            edit:false,
            category_id:undefined,
            new_name:'',
            edit_index:undefined,
            edit_progress:false,
            edit_feedback:'',

            edit_sub:false,
            edit_alert_sub:false,
            new_name_sub:'',
            edit_feedback_sub:'',
            edit_progress_sub:false,
            category_id_sub:undefined,
            edit_index_sub:undefined,
            //
            loadProductBy:'STANDARD',//CATEGORY,STANDARD,SEARCH
            current_page:1,
            last_page:1,
            total_products:0,
            no_search_results:false,
            load_user:true,
            fill:"",
            progress:false,
            alert_box:false,
            alert_type:"success",
            alert_message:undefined,
            sub_cat_cat:undefined,
            new_category_name:"",
            new_sub_category_name:"",
            category_image_notice:undefined,
            sub_category_image_notice:undefined,
            meta:[
                
            ],
            metasubedit:[],
            theme:'#fe7b00',
            search_product:"",
            selected_category:undefined,
            cats_selected:[
               
            ],
            current_selected_product_seller:{
                    firstname:"success",
                    lastname:"onyegbanokwu",
                    address:'',
                    phone:'09034567890',
                    email:'sonobktech@gmail.com',
                    wallet_id:'3100234567',
                    status:1,
                    id:1,
                    profile_picture:'',
                    nin:'67898765434',
                    gender:"male"
            },
            current_selected_images:[],
            current_selected_product:{
                    id:1,
                    user:2,
                    product_name:"Product Name",
                    description:"Product Description Description Product Description Product Description Product Description ",
                    price:25000,
                    is_bidable:true,
                    bid_duration:"",
                    bid_start_time:"",
                    category:1,
                    subcategory:2,
                    quantity:3,
                    images:"a.png:b.jpg:c.png",
                    discount:3,
                    short_description:"",
                    coupon:"",
                    created_at:"",
                    updated_at:""
            },
            /**
             * TEST PRODUCT IMAGE
             */
            test_image:[
                require('../assets/5.jpg'),
                require("../assets/3.jpg"),
                require("../assets/2.jpg"),
                require("../assets/1.jpg")
                ],
            
            /**
             * TESY
             */
            product_categories:[
             /*  {id:1 , name:'Subcategory 1' , image:"" ,  slug:"sub-category-1"},
                 {id:1 , name:'Subcategory 1' , image:"" , slug:"sub-category-1"},
                   {id:1 , name:'Subcategory 1' , image:"" ,slug:"sub-category-1"}*/
            ],
            product_subcategories:[
              /* {id:1 , name:'Subcategory 1' , image:"" , category:1 , slug:"sub-category-1"},
                 {id:1 , name:'Subcategory 1' , image:"" , category:1 , slug:"sub-category-1"},
                   {id:1 , name:'Subcategory 1' , image:"" , category:1 , slug:"sub-category-1"}*/
            ],
            products:[
                 {
                    id:1,
                    user:2,
                    product_name:"Product Name",
                    description:"",
                    price:25000,
                    is_bidable:true,
                    bid_duration:"",
                    bid_start_time:"",
                    category:1,
                    subcategory:2,
                    quantity:3,
                    images:"a.png:b.jpg:c.png",
                    discount:3,
                    short_description:"",
                    coupon:"",
                    created_at:"",
                    preview:"",
                    updated_at:""
                }
            ],
            subcategories_metadata:[],
            product_to_delete:undefined,
            delete_prompt_product:false
        }
    },
    components:{
  
    },
    methods:{
        openDelete(index){
            this.product_to_delete = index;
            this.delete_prompt_product = true;
        },
        closeMetaEdit(){
            this.edit_meta_data=false;
            this.meta_options=undefined;
            this.meta_type=undefined;
        },
        deleteCategoryModal(index){
            this.delete_index = index;
            this.cat_name_x = this.product_categories[index].name;
            this.delete_prompt_category=true;
        },
        deleteSubCategoryModal(index){
            this.delete_index = index;
            this.subcat_name_x = this.product_subcategories[index].name;
            this.delete_prompt_subcategory=true;
        },
        deleteProduct(){
            this.delete_prompt_product = false;
            this.$store.dispatch('openProgress');
            this.axios({
                url:this.$store.getters.get_api+'product/'+this.products[this.product_to_delete].id,
                method:'delete',
                headers:{
                    "Access-Control-Allow-Origin":"*",
                    'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((res)=>{
                if(!res.data.error){
                    this.products.splice(this.product_to_delete,1)
                    this.$store.commit('set_error',false)
                    this.$store.commit('set_success',true)
                    this.$store.commit('set_progress',false)
                    this.$store.commit('set_message',res.data.message)
                }
            }).catch((error)=>{
               // this.edit_meta_data=false;
                //this.edit_sub=false;
                this.$store.commit('set_error',true)
                this.$store.commit('set_success',false)
                this.$store.commit('set_progress',false)
                this.$store.commit('set_message','Network error')
                    throw error;
            })
        },
        submitEdited(){
            if(this.new_name.length>1){
               this.edit_progress=true;
               const form = new FormData();
               form.append('category', this.category_id)
               form.append('name' , this.new_name);
               const len = document.getElementById('edit_cat_img')
               if(len.files.length===1){
                form.append('image',document.getElementById('edit_cat_img').files[0]);
               }
               
               this.axios({
                url:this.$store.getters.get_api+'editCategory',
                method:'post',
                data:form,
                headers:{
                    "Access-Control-Allow-Origin":"*",
                    'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
               }).then((res)=>{
                const data = res.data;
               // console.log(data)
                if(!data.error){

                    document.getElementById('edit_cat_img').files=null;
                    this.product_categories[this.edit_index].id=data.message.id
                    this.product_categories[this.edit_index].name=data.message.name;
                    this.product_categories[this.edit_index].slug=data.message.slug;
                    this.product_categories[this.edit_index].image=data.message.image;
                }
                this.edit_feedback="Successful!";
                this.edit_progress=false;
                this.edit=false;
               }).catch((error)=>{
                return error
               })
            }
        },
        updateMetadata(){
            let form = new FormData();
            form.append('type', this.meta_type)
            form.append('options', this.meta_options)
            form.append('meta', this.metadataforedit.id)
            this.$store.dispatch('openProgress');
            this.axios({
                url:this.$store.getters.get_api+'edit_meta',
                data:form,
                method:'post',
                headers:{
                    "Access-Control-Allow-Origin":"*",
                    'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((res)=>{
                if(!res.data.error){
                    this.$store.commit('set_error',false)
                    this.$store.commit('set_success',true)
                    this.$store.commit('set_progress',false)
                    this.$store.commit('set_message','Meta data updated')
                }
            }).catch((error)=>{
               // this.edit_meta_data=false;
                //this.edit_sub=false;
                this.$store.commit('set_error',true)
                this.$store.commit('set_success',false)
                this.$store.commit('set_progress',false)
                this.$store.commit('set_message','Network error')
                    throw error;
            })
        },
        editMetat(index){
            this.metadataforedit = this.subcategories_metadata[index];
            this.meta_type = this.metadataforedit.type;
            this.meta_options = this.metadataforedit.options;
            this.edit_meta_data=true;
        },
        removeMetax(meta,index){
            this.$store.dispatch('openProgress')
            this.axios({
                url:this.$store.getters.get_api+'removeMeta/'+meta,
                method:'get',
                headers:{
                    "Access-Control-Allow-Origin":"*",
                    'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((res)=>{
                if(!res.data.error){
                    this.subcategories_metadata.splice(index,1);
                    this.$store.dispatch('closeProgress')
                }
            }).catch((error)=>{
                this.$store.dispatch('closeProgress')
                throw error;
            })
        },
        submitEditedSub(){
            if(this.new_name_sub.length>1){
               this.edit_progress_sub=true;
               const form = new FormData();
               form.append('subcategory', this.category_id_sub)
               form.append('name' , this.new_name_sub);
               const len = document.getElementById('edit_cat_img_sub')
               if(len.files.length===1){
                form.append('image',document.getElementById('edit_cat_img_sub').files[0]);
               }
               if(this.metasubedit.length>=1){
                   form.append('new_sub_meta',JSON.stringify(this.metasubedit))
               }
               
               this.axios({
                url:this.$store.getters.get_api+'editSubCategory',
                method:'post',
                data:form,
                headers:{
                    "Access-Control-Allow-Origin":"*",
                    'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
               }).then((res)=>{
                const data = res.data;
              //  console.log(data)
                if(!data.error){
                    this.edit_sub=false;
                    document.getElementById('edit_cat_img_sub').files=null;
                    this.product_subcategories[this.edit_index_sub].id=data.message.id
                    this.product_subcategories[this.edit_index_sub].name=data.message.name;
                    this.product_subcategories[this.edit_index_sub].slug=data.message.slug;
                    this.product_subcategories[this.edit_index_sub].image=data.message.image;
                }
                this.edit_feedback_sub="Successful!";
                this.edit_progress_sub=false;
               }).catch((error)=>{
                return error
               })
            }
        },
        editCategory(index){
            const category = this.product_categories[index];
            this.edit_index=index;
            this.edit=true;
            this.new_name = category.name;
            this.category_id=category.id;
        },
        editSubCategory(index){
            this.subcategories_metadata=[];
            const subcategory = this.product_subcategories[index];
            this.edit_index_sub=index;
            this.edit_sub=true;
            this.new_name_sub = subcategory.name;
            this.category_id_sub=subcategory.id;
            this.loadSubcategoryMetaData(subcategory.id)
        },
        loadSubcategoryMetaData(subcategory){
            this.axios({
                url:this.$store.getters.get_api+"loadsubcatmeta/"+subcategory,
                method:'get',
                headers:{
                    "Access-Control-Allow-Origin":"*"
                }
            }).then((res)=>{
                console.log(res.data)
                if(!res.data.error){
                    this.subcategories_metadata = res.data.message;
                }
            }).catch((error)=>{
                throw error;
            });
        },
        editCatImg(){
            this.edit_feedback = "1 image selected!";
            this.edit_alert=true;
        },
        editCatImgSub(){
            this.edit_feedback_sub = "1 image selected!";
            this.edit_alert_sub=true;
        },
        clickEditImgSelect(){
            document.getElementById('edit_cat_img').click();
        },
        clickEditImgSelectSub(){
            document.getElementById('edit_cat_img_sub').click();
        },
        /*loadPreviousProducts(){
            if(this.current_page>1){
                this.current_page -=1;
                this.getAllProducts(this.current_page,this.loadProductBy);
            }
        },
        loadNextProducts(){
            if(this.current_page<this.total_products){
                this.current_page +=1;
                this.getAllProducts(this.current_page, this.loadProductBy);
            }
        },*/
        productInfo(index){
            this.current_selected_product=this.products[index];
            this.$router.push('/productInformation/'+this.current_selected_product.slog);
        },
        searchAllProducts(){
            this.no_search_results = false;
            this.axios({
                url:this.$store.getters.get_api+"searchAllProducts/"+this.search_product,
                method:'get',
                headers:{
                    "Access-Control-Allow-Origin":"*"
                }
            }).then((response)=>{
                const data = response.data
                console.log(data)
                if(!data.error){
                    this.products = data.message.data
                    if(this.products.length<1){
                        this.no_search_results = true;
                    }
                    else{
                        this.products.forEach( function(element) {
                        element.preview = element.images.split(":")[0];
                    });
                    }
                }
            }).catch((error)=>{
                return error
            });
        },
        getAllProducts(page,type){
            this.$store.dispatch('openProgress')
            let url='';
            switch (type) {
                case 'STANDARD':
                    url = this.$store.getters.get_api+"fetchAllProducts/"+page
                    break;
                case 'CATEGORY':
                    url = this.$store.getters.get_api+"fetchAllProductsCategory/"+this.selected_category+'/'+page
                    break;
                case 'SEARCH':
                    url = this.$store.getters.get_api+"searchAllProducts/"+this.search_product+'/'+page
                    break;
            }
            this.axios({
                url:url,
                method:"get",
                headers:{
                    "Access-Control-Allow-Origin":"*"
                }
            }).then((response)=>{
                const data = response.data
               // console.log(data)
                if(!data.error){
                    this.$store.dispatch('closeProgress')
                    this.products = data.message.data
                    this.total_products = data.message.meta.total
                    this.last_page = data.message.meta.last_page
                    this.products.forEach( function(element) {
                        element.preview = element.images.split(":")[0];
                    });
                }else{
                    this.$store.commit('set_progress',false);
                  this.$store.commit('set_error',true);
                  this.$store.commit('set_message',data.message);
                }
            }).catch((error)=>{
                this.$store.commit('set_progress',false);
                this.$store.commit('set_error',true);
                this.$store.commit('set_message','Network Error');
                return error
            })
        },
        addMeta(){
            this.meta.push({name:''});
        },
        addMetasubedit(){
            this.metasubedit.push({name:''});
        },
        getAllCategories(){
            this.axios({
                url:this.$store.getters.get_api+"fetchAllCategories",
                method:"get",
                headers:{
                    "Access-Control-Allow-Origin":"*"
                }
            }).then((res)=>{
                const data = res.data;
                if(!data.error){
                    this.product_categories = data.message;
                    data.message.forEach((e)=>{
                        this.cats_selected.push({text:e.name , value:e.id})
                    })
                }
            }).catch((error)=>{
                return error;
            })
        },
         getAllSubCategories(){
            this.axios({
                url:this.$store.getters.get_api+"fetchAllSubcategories",
                method:"get",
                headers:{
                    "Access-Control-Allow-Origin":"*"
                }
            }).then((res)=>{
                const data = res.data;
                if(!data.error){
                    this.product_subcategories = data.message;
                }
            }).catch((error)=>{
                return error;
            })
        },
             removeSubCategory(){
            this.alert_box=false
            this.progress=true
            this.overlay=true
            this.delete_prompt_subcategory=false;
            //console.log(this.product_categories[index].id)
            this.axios({
                url:this.$store.getters.get_api+"deleteSubcategory/"+this.product_subcategories[this.delete_index].id,
                method:"get",
                headers:{
                    "Access-Control-Allow-Origin":"*",
                    "Authorization":"Bearer "+this.$store.getters.get_token
                }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.alert_box=true
                    this.alert_type="success"
                    this.alert_message="Sub Categories deleted"
                    this.progress = false
                    this.product_subcategories.splice(this.delete_index,1)
                    //this.cats_selected.splice(index,1)
                }
            }).catch((error)=>{
                return error
            })
        },
        removeCategory(){
            this.delete_prompt_category=false;
            this.alert_box=false
            this.progress=true
            this.overlay=true
            //console.log(this.product_categories[index].id)
            this.axios({
                url:this.$store.getters.get_api+"deleteCategory/"+this.product_categories[this.delete_index].id,
                method:"get",
                headers:{
                    "Access-Control-Allow-Origin":"*",
                    "Authorization":"Bearer "+this.$store.getters.get_token
                }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.alert_box=true
                    this.alert_type="success"
                    this.alert_message="Categories deleted"
                    this.progress = false
                    this.product_categories.splice(this.delete_index,1)
                    this.cats_selected.splice(this.delete_index,1)
                }
            }).catch((error)=>{
                return error
            })
        },
        removeMeta(index){
            this.meta.splice(index,1);
        },
        removeMetasubedit(index){
            this.metasubedit.splice(index,1);
        },
        selectSubCategoryImage(){
            document.getElementById("new_sub_category_image").click();
        },
        selectCategoryImage(){
            document.getElementById("new_category_image").click();
        },
        selectionListener(){
            this.category_image_notice="1 image selected";
        },
        hideOverlay(){
            this.overlay = false;
            this.progress = false;
            this.alert_box = false;
        },
        submitSubcategory(){
            const form = new FormData()
            form.append("image" , document.getElementById("new_sub_category_image").files[0]);
            form.append("name" , this.new_sub_category_name)
            form.append("category" , this.sub_cat_cat);
            form.append("meta" , JSON.stringify(this.meta))
            this.alert_box=false;
            this.overlay=true;
            this.progress=true;
            this.axios({
                url:this.$store.getters.get_api+"createSubcategory",
                method:"post",
                data:form,
                headers:{
                    'Access-Control-Allow-Origin':'*',
                    "Authorization":"Bearer "+this.$store.getters.get_token
                }
            }).then((res)=>{
                const data = res.data;
                if(!data.error){
                    this.alert_box=true;
                    this.overlay=true;
                    this.progress=false;
                    this.alert_type="success"
                    this.alert_message="Product sub-category added"
                    this.product_subcategories = data.message;
                    this.new_category_image=null;
                    this.sub_cat_cat=null;
                    this.meta=undefined;
                }
                else{
                    this.alert_box=true;
                    this.overlay=true;
                    this.progress=false;
                    this.alert_type="error"
                    this.alert_message="Server error"
                }
            }).catch((error)=>{
              return error;
            });
        },
        submitCategory(){
            if(this.new_category_name)
            {
            const form = new FormData()
            form.append("image" , document.getElementById("new_category_image").files[0]);
            form.append("name" , this.new_category_name)
            this.alert_box=false;
            this.overlay=true;
            this.progress=true;
            this.axios({
                url:this.$store.getters.get_api+"createCategory",
                method:"post",
                data:form,
                headers:{
                    'Access-Control-Allow-Origin':'*',
                    "Authorization":"Bearer "+this.$store.getters.get_token
                }
            }).then((res)=>{
                const data = res.data;
                if(!data.error){
                    this.alert_box=true;
                    this.overlay=true;
                    this.progress=false;
                    this.alert_type="success"
                    this.alert_message="Product category added"
                    this.product_categories = data.message;
                }
                else{
                    this.alert_box=true;
                    this.overlay=true;
                    this.progress=false;
                    this.alert_type="error"
                    this.alert_message="Server error"
                }
            }).catch((error)=>{
              return error;
            });
        }
    },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
    },
    created(){

    },
    mounted(){
        this.sideMenu=this.$store.getters.get_menu;
      this.getAllProducts(1,this.loadProductBy)
        this.getAllCategories()
        this.getAllSubCategories()
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
        search_product(){
            this.loadProductBy = 'SEARCH';
        },
        selected_category(){
            this.loadProductBy = 'CATEGORY'
            this.getAllProducts(1 , this.loadProductBy)
        },
        current_page(value){
             this.getAllProducts(value, this.loadProductBy);
        }
    },
}
</script>
<style scoped>
*{
    border:0px;
}
.prompt{
    text-align:center;
    padding:6px 1px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}

.img{
    border-top-left-radius: 10px;
    border-top-right-radius:10px;
}
.container{
    min-width:100%;
    min-height:100%;
}
.img_cont{
    height:100px;
    width:100px;
    box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
    border-radius:20px;
    margin:2px;
}
.imgx{
    width:100%;
    height:100%;
    object-fit:cover;
    border-radius:20px;
}
.product{
    position:relative;
}

@media screen and (max-width:620px){
    .product{
        width:250px;
        margin-bottom: 1em;
    }
    .products{
        max-width:500px;
        display:grid;
        justify-content: center;
        grid-template-rows: auto;
        grid-template-columns:250px;
        column-gap: 5px;
        grid-row-gap: 5px;
    }
    .data{
        display:grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto;
        grid-row-gap: 5px;
    }
    .searchContainer{
        display:grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto;
        grid-row-gap: 5px;
    }
    
}

@media screen and (min-width:640px){
    .product{
        width: 200px;
        margin:4px;
    }
    .products{
        display:grid;
        justify-content: center;
        grid-template-rows: auto;
        grid-template-columns: 250px 250px;
        column-gap: 5px;
        grid-row-gap: 5px;
    }
    .data{
        display:grid;
        grid-template-rows: auto;
        grid-template-columns:50% 50%;
        column-gap: 5px;
    }
    .settings{
       width:40%;
    }
    .searchContainer{
        display:grid;
        grid-template-rows:auto;
        grid-template-columns:20% 20%;
        column-gap: 5px;
    }
   
}
.more_data_icon{
    position:absolute;
    z-index: 5;
    top:2px;
    right:3px;
    padding-bottom:2px;
}
.more_data_icon1{
    position:absolute;
    z-index: 5;
    right:50px;
    top:2px;
    padding-bottom:2px;
}
.product{
    position:relative;
}
.cardx{
    position:'relative';
}
.close{
    position:absolute;
    right:2px;
    top:2px;
}
.chip{
    margin:2px;
}
.price_data_icon{
  position:absolute;
   z-index: 5;
   top:17%;
   right:2px;
}
.cdd{
    padding:5px;
    box-shadow:1px 1px rgba(0,0,0,.5),-1px -1px rgba(0,0,0,.5);
}
</style>